<template>
  <div class="courses innerpage">
    <Header></Header>
    <v-container>
      <v-row>
        <v-col mt="4">
          <h1>Quizzes</h1>
        </v-col>
      </v-row>
      <v-divider class="mt-3 mb-6"></v-divider>
      <v-row v-if="isLoaded">
        <v-data-table
          :loading="dataLoading"
          loading-text="Loading... Please wait"
          item-key="id"
          :disable-sort="true"
          :headers="quizzesHeaders"
          :items="quizzes"
          :hide-default-header="true"
          :hide-default-footer="true"
          :sort-desc="[false, true]"
          multi-sort
          class="elevation-5 mb-5 align-center"
        >
          <template v-slot:item.edit="{ item }">
            <v-icon class="pa-6 " @click="goToUrl('quizquestions', item.id)">
              mdi-playlist-edit
            </v-icon>
          </template>
          <template v-slot:item.delete="{ item }">
            <v-btn :disabled="item.custom_course === null ? false : true" color="primary" @click="deleteItem(item)" rounded>
              <v-icon left>
                mdi-delete
              </v-icon>
              Delete
            </v-btn>
          </template>
          <template v-slot:item.start="{ item }">
            <v-btn
              class=""
              color="primary"
              @click="goToUrl('quizsingle', item.id)"
              rounded
            >
              Launch
            </v-btn>
          </template>
        </v-data-table>
      </v-row>
      <v-row v-else>
        <!-- loading -->
        <v-col cols="12">
          <p class="text-center text-overline mb-8">Loading quizzes</p>
          <v-progress-linear
            color="secondary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <v-snackbar v-model="snackbar" right top>
            {{ alert }}
            <template v-slot:action="{ attrs }">
              <v-btn color="white" text v-bind="attrs" @click="snackbar = false"
                >Close</v-btn
              >
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header";
import CoursesService from "../services/courses.service";
import { Role } from "@/helpers/roles";

export default {
  components: {
    Header
  },
  data: () => ({
    snackbar: false,
    alert: "",
    quizzes: [],
    isLoaded: false,
    dataLoading: false,
    quizzesHeaders: [
      {
        text: "Quiz Name",
        value: "name",
        width: "30%"
      },
      {
        text: "Quiz Type",
        value: "type",
        width: "25%"
      },
      {
        text: "",
        value: "edit",
        width: "50%"
      },
      {
        text: "",
        value: "delete",
        width: "50%"
      },
      {
        text: "Start",
        value: "start",
        width: "50%"
      }
    ]
  }),

  computed: {
    isOrgAdmin() {
      let orgAdmin = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.OrgAdmin) {
          orgAdmin = true;
        }
      });
      return orgAdmin;
    },

    currentUser() {
      return this.$store.state.auth.user;
    },

    orgId() {
      return this.$store.state.auth.user.user.organizations.id;
    },

    custom() {
      return this.isAdminEditor ? 0 : 1;
    },

    formTitle() {
      return this.editedIndex === -1 ? "Create New Course" : "Edit Course";
    }
  },
  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    } else {
      this.initialize();
    }
  },

  methods: {
    async initialize() {
      try {
        const response = await CoursesService.getQuizzes();
        this.quizzes = response.data.data;
        this.isLoaded = true;
      } catch (e) {
        console.log("error getting quizzes", e);
      }
    },
    goToUrl(myUrl, quiz_id) {
      this.$router.push({
        path: "/" + myUrl + "/" + quiz_id
      });
    },
    async deleteItem(item) {
      const index = this.quizzes.indexOf(item);
      confirm("Are you sure you want to delete this quiz?") &&
        this.quizzes.splice(index, 1);

      await CoursesService.removeQuiz({
        id: item.id,
        data: {
          custom: this.custom,
          organization_id: this.orgId
        }
      });

      this.showSnackbar(item.name + " has been deleted");
    },
    showSnackbar(msg) {
      this.alert = msg;
      this.snackbar = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.col {
  flex-grow: 0;
}
.courses-card {
  padding-bottom: 10px;
  .header-card {
    color: #3691b3;
  }
  .title-card {
    font-family: "Roboto", sans-serif;
    color: rgba(0, 0, 0, 0.6);
  }

  .text-card {
    font-family: "Roboto", sans-serif;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0.5px;
    line-height: 21px;
  }
}
</style>
