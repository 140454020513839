import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL + "/";
let http;

class OrgCoursesService {
  constructor() {
    http = axios.create({
      baseURL: API_URL,
      headers: authHeader()
    });
    http.interceptors.request.use(
      config => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user.token) {
          config.headers.Authorization = "Bearer " + user.token;
        }
        return config;
      },
      error => Promise.reject(error)
    );
  }

  get({ query }) {
    return http.get(`user_organization_courses`, {
      params: query,
      headers: authHeader()
    });
  }

  getAllCourses() {
    return http.get(`courses`, {
      headers: authHeader()
    });
  }

  getCourse(courseId) {
    return axios.get(`${API_URL}course/${courseId}`, {
      headers: authHeader()
    });
  }

  create({ data, query }) {
    return http.post(`course`, data, {
      params: query,
      headers: authHeader()
    });
  }

  update({ data, query }) {
    return http.post(`update_course/`, data, {
      params: query,
      headers: authHeader()
    });
  }

  remove({ id }) {
    return http.delete(`course/${id}`);
  }

  createChapter({ data, query }) {
    return http.post(`chapter`, data, {
      params: query,
      headers: authHeader()
    });
  }

  updateChapter({ data, query }) {
    return http.post(`update_chapter/`, data, {
      params: query,
      headers: authHeader()
    });
  }

  removeChapter({ id }) {
    return http.delete(`chapter/${id}`);
  }

  getQuiz(quizId) {
    return axios.get(`${API_URL}quiz/${quizId}`, {
      headers: authHeader()
    });
  }

  getQuizzes() {
    return axios.get(`${API_URL}quizzes`, {
      headers: authHeader()
    });
  }

  createQuizQuestions({ data }) {
    return axios.post(`${API_URL}quiz_questions`, data, {
      headers: authHeader()
    });
  }

  updateQuiz({ id, data, query }) {
    return http.put(`quiz/${id}`, data, {
      params: query,
      headers: authHeader()
    });
  }

  removeQuizQuestion({ id, data }) {
    return http.delete(`quiz_question/${id}`, {
      data,
      headers: authHeader()
    });
  }

  removeQuiz({ id, data }) {
    return http.delete(`quiz/${id}`, {
      data,
      headers: authHeader()
    });
  }

  getCategory({ id }) {
    return axios.get(`${API_URL}category/${id}`, {
      headers: authHeader()
    });
  }

  getCategories() {
    return http.get(`categories`, {
      headers: authHeader()
    });
  }

  createCategory({ data, query }) {
    return http.post(`category`, data, {
      params: query,
      headers: authHeader()
    });
  }

  updateCategory({ id, data, query }) {
    return http.put(`category/${id}`, data, {
      params: query,
      headers: authHeader()
    });
  }

  removeCategory({ id }) {
    return http.delete(`category/${id}`);
  }

  createQuestion({ data, query }) {
    return http.post(`question`, data, {
      params: query,
      headers: authHeader()
    });
  }

  updateQuestion({ id, data, query }) {
    return http.put(`question/${id}`, data, {
      params: query,
      headers: authHeader()
    });
  }

  removeQuestion({ id, data }) {
    return http.delete(`question/${id}`, {
      data,
      headers: authHeader()
    });
  }

  getQuestion({ id }) {
    return axios.get(`${API_URL}question/${id}`, {
      headers: authHeader()
    });
  }

  getQuestions({ query }) {
    return http.get(`questions`, {
      params: query,
      headers: authHeader()
    });
  }

  getQuestionsSearch({ data, query }) {
    console.log(data);
    return http.post(`questions_search`, data, {
      params: query,
      headers: authHeader()
    });
  }

  getQuestionsByCategory({ id, query }) {
    return http.get(`${API_URL}questions_by_category/${id}`, {
      params: query,
      headers: authHeader()
    });
  }

  getResponses({ question_id }) {
    return axios.get(`${API_URL}responses/${question_id}`, {
      headers: authHeader()
    });
  }

  createResponses({ data, query }) {
    return http.post(`responses`, data, {
      params: query,
      headers: authHeader()
    });
  }

  createQuestionConditions({ data, query }) {
    console.log("conds", data);
    return http.post(`question_conditions`, data, {
      params: query,
      headers: authHeader()
    });
  }

  updateQuestionCondition({ id, data, query }) {
    return http.put(`question_condition/${id}`, data, {
      params: query,
      headers: authHeader()
    });
  }

  createQuestionClone({ category_id, data }) {
    return axios.post(`${API_URL}question_clone/${category_id}`, data, {
      headers: authHeader()
    });
  }

  removeQuestionCondition({ id }) {
    return http.delete(`question_condition/${id}`);
  }

  getRiskProfiles() {
    return http.get(`risk_profiles`, {
      headers: authHeader()
    });
  }

  createRiskProfile({ data, query }) {
    return http.post(`risk_profile`, data, {
      params: query,
      headers: authHeader()
    });
  }

  updateRiskProfile({ id, data, query }) {
    return http.put(`risk_profile/${id}`, data, {
      params: query,
      headers: authHeader()
    });
  }

  removeRiskProfile({ id, data }) {
    return http.delete(`risk_profile/${id}`, {
      data,
      headers: authHeader()
    });
  }

  getReportTemplates() {
    return http.get(`report_templates`, {
      headers: authHeader()
    });
  }

  removeQuizRiskProfiles({ data }) {
    //return http.delete(`quiz_risk_profile/${id}`);
    return http.delete(`quiz_risk_profile`, {
      data,
      headers: authHeader()
    });
  }

  createQuizRiskProfile({ data, query }) {
    return http.post(`quiz_risk_profile`, data, {
      params: query,
      headers: authHeader()
    });
  }

  getCBlocks({ quizId }) {
    return http.get(`report_template_custom_blocks/${quizId}`, {
      headers: authHeader()
    });
  }

  createCBlock({ data, query }) {
    return http.post(`report_template_custom_block`, data, {
      params: query,
      headers: authHeader()
    });
  }

  updateCBlock({ id, data, query }) {
    return http.put(`report_template_custom_block/${id}`, data, {
      params: query,
      headers: authHeader()
    });
  }

  removeCBlock({ id }) {
    return http.delete(`report_template_custom_block/${id}`);
  }
}

export default new OrgCoursesService();
