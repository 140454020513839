var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"courses innerpage"},[_c('Header'),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"mt":"4"}},[_c('h1',[_vm._v("Quizzes")])])],1),_c('v-divider',{staticClass:"mt-3 mb-6"}),(_vm.isLoaded)?_c('v-row',[_c('v-data-table',{staticClass:"elevation-5 mb-5 align-center",attrs:{"loading":_vm.dataLoading,"loading-text":"Loading... Please wait","item-key":"id","disable-sort":true,"headers":_vm.quizzesHeaders,"items":_vm.quizzes,"hide-default-header":true,"hide-default-footer":true,"sort-desc":[false, true],"multi-sort":""},scopedSlots:_vm._u([{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"pa-6 ",on:{"click":function($event){return _vm.goToUrl('quizquestions', item.id)}}},[_vm._v(" mdi-playlist-edit ")])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":item.custom_course === null ? false : true,"color":"primary","rounded":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-delete ")]),_vm._v(" Delete ")],1)]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":function($event){return _vm.goToUrl('quizsingle', item.id)}}},[_vm._v(" Launch ")])]}}],null,false,425519821)})],1):_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-center text-overline mb-8"},[_vm._v("Loading quizzes")]),_c('v-progress-linear',{attrs:{"color":"secondary","indeterminate":"","rounded":"","height":"6"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-snackbar',{attrs:{"right":"","top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v("Close")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.alert)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }